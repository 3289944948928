<template>

    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg" 
        class="mr-1"
    >
        <path
            d="M7.09346 12.8544C10.2749 12.8544 12.8539 10.2754 12.8539 7.09395C12.8539 3.91254 10.2749 1.3335 7.09346 1.3335C3.91205 1.3335 1.33301 3.91254 1.33301 7.09395C1.33301 10.2754 3.91205 12.8544 7.09346 12.8544Z"
            :stroke="strokeColor" 
            stroke-width="1.33333" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
        />
        <path 
            d="M14.6665 14.667L11.4443 11.4448" 
            :stroke="strokeColor" 
            stroke-width="1.33333" 
            stroke-linecap="round"
            stroke-linejoin="round" 
        />
    </svg>
</template>

<script>

export default {
    props: {
        width : {
        type : Number,
        default: 16
        },
        height : {
            type: Number,
            default: 16
        },
        strokeColor : {
            type: String,
            default: "#FFFFFF"
        }
    }
}

</script>

