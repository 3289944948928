<template>
    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.33333 14C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H10.7833C10.9611 2 11.1307 2.03333 11.292 2.1C11.4529 2.16667 11.5944 2.26111 11.7167 2.38333L13.6167 4.28333C13.7389 4.40556 13.8333 4.54711 13.9 4.708C13.9667 4.86933 14 5.03889 14 5.21667V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H3.33333ZM12.6667 5.23333L10.7667 3.33333H3.33333V12.6667H12.6667V5.23333ZM8 12C8.55556 12 9.02778 11.8056 9.41667 11.4167C9.80556 11.0278 10 10.5556 10 10C10 9.44444 9.80556 8.97222 9.41667 8.58333C9.02778 8.19444 8.55556 8 8 8C7.44444 8 6.97222 8.19444 6.58333 8.58333C6.19444 8.97222 6 9.44444 6 10C6 10.5556 6.19444 11.0278 6.58333 11.4167C6.97222 11.8056 7.44444 12 8 12ZM4.66667 6.66667H9.33333C9.52222 6.66667 9.68067 6.60267 9.80867 6.47467C9.93622 6.34711 10 6.18889 10 6V4.66667C10 4.47778 9.93622 4.31933 9.80867 4.19133C9.68067 4.06378 9.52222 4 9.33333 4H4.66667C4.47778 4 4.31933 4.06378 4.19133 4.19133C4.06378 4.31933 4 4.47778 4 4.66667V6C4 6.18889 4.06378 6.34711 4.19133 6.47467C4.31933 6.60267 4.47778 6.66667 4.66667 6.66667ZM3.33333 5.23333V12.6667V3.33333V5.23333Z"
            :stroke="strokeColor" 
        />
    </svg>

</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#5968DD"
        },
        width : {
            type : Number,
            default: 16
        },
        height : {
            type: Number,
            default: 16
        },
    }
};
</script>

<style></style>
