<template>
    <div class="row mt-3">
        <div class="col-12 col-md-8 col-lg-9">
            <div class="day-calendar-appointment">
                <FullCalendar
                    ref="fullCalendar"
                    defaultView="resourceTimeGridDay"
                    :plugins="timeGridPlugins"
                    :schedulerLicenseKey="'GPL-My-Project-Is-Open-Source'"
                    :resources="resources"
                    :slotDuration="'00:05'"
                    :minTime="'07:00:00'"
                    :events="events"
                    :event-render="eventRender"
                    :eventTimeFormat="eventTimeFormat"
                    :slotLabelFormat="slotLabelFormat"
                    :nowIndicator="nowIndicator"
                    :eventOrder="'displayOrder'"
                    :header="false"
                    :progressiveEventRendering="true"
                ></FullCalendar>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
            <side-panel @clickToggle="toggle" ref="notif"></side-panel>
        </div>
        <ModalInternal v-if="showModalInternal"></ModalInternal>
        <!-- <modal-reservasi v-if="showModalReservasi"></modal-reservasi> -->
        <ModalReservasiNew :data-slot="selectedSlot" v-if="showModalReservasi"></ModalReservasiNew>
        <modal-whatsapp :id-appointment="idAppointmentSelect" v-if="showModalWA"></modal-whatsapp>
        <modal-waiting v-if="showModalWaiting"></modal-waiting>
        <!-- <modalBlock
            v-if="showModalBlock"
        ></modalBlock> -->
        <modal-help
            v-if="showModalHelp"
        ></modal-help>
        <Modal-Temp-Wa
         v-if="showModalTempWa"
        ></Modal-Temp-Wa>
         <modal-payment-link
         v-if="showModalTelekonsul"
        ></modal-payment-link>
        <!-- <modal-mrreg
            v-if="showModalMrReg"
            :modal-data="detailPanel"
        ></modal-mrreg> -->
        <indexInputMrn
            :modal-data="detailPanel"
            v-if="showModalMrReg"
        ></indexInputMrn>
        <modal-history-reservasi
            v-if="showModalHistory"
        ></modal-history-reservasi>
        <ModalPrinter
            v-if="showModalPrinter"
            :payload="payloadPrint"
        ></ModalPrinter>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { mapState, mapMutations, mapActions } from "vuex";
import CalendarEvent from "./_components/CalendarEvent";
import SidePanel from "./_components/SidePanel";
import LoadSpinner from "./../../_general/LoadSpinner";
import QueueDevice from "../../../model/queue-device-model";
import indexInputMrn from "../../reservations/indexInputMrn.vue"
import ModalReservasiNew from "../ModalReservasiNew/LoadingModalReservasi.vue"

// funtion print di bawah belum kepegang gan
import qz from "qz-tray";
const { RSAKey, KJUR, KEYUTIL, stob64, hextorstr } = require("jsrsasign");
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
    components: {
        ModalReservasiNew,
        indexInputMrn,
        FullCalendar,
        SidePanel,
        // ModalReservasi: () => ({
        //     component: new Promise(function(resolve, reject) {
        //         setTimeout(function() {
        //             resolve(
        //                 import(/* webpackChunkName: "modal-reservasi-dokter" */ "./../ModalReservasi/ModalReservasi.vue")
        //             );
        //         }, 1000);
        //     }),
        //     loading: LoadSpinner
        // }),
        ModalMrreg: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-reservation-mr-reg" */ "../../reservations/_components/ModalMrReg")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalTempWa: () => ({
            component: new Promise(function (resolve, reject) {
                setTimeout(function () {
                resolve(
                    import(
                    /* webpackChunkName: "modal-reservation-mr-reg" */ "../../doctor-appointments/ModalReservasi/_components/ModalTempWa"
                    )
                );
                }, 1000);
        }),
        loading: LoadSpinner
        }),
        ModalPaymentLink: () => ({
            component: new Promise(function (resolve, reject) {
                setTimeout(function () {
                resolve(
                    import(
                    /* webpackChunkName: "modal-reservation-mr-reg" */ "../../doctor-appointments/ModalReservasi/_components/ModalPaymentLink"
                    )
                );
                }, 1000);
        }),
        loading: LoadSpinner
        }),
        ModalWaiting: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-waiting-list" */ "./../ModalWaiting/ModalWaiting.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalInternal: () => ({
            component: 
            new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-internal-event" */ "../ModalInternalEvent/ModalInternalEvent.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalBlock: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-block" */ "../modalBlock/modalBlock")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalHelp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-help" */ "../ModalHelp/ModalHelp")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        ModalHistoryReservasi: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import(/* webpackChunkName: "modal-history-reservasi" */ "./_components/ModalHistory.vue")
                    );
                }, 500);
            })
        }),
        ModalPrinter: () => ({
                component: new Promise(function (resolve, reject) {
                    setTimeout(() => {
                        resolve(import("../../_select/SelectPrinter.vue"))
                    }, 1000)
                })
            }),
        ModalWhatsapp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import(/* webpackChunkName: "modal-history-reservasi" */ "./_components/ModalWhatsapp.vue")
                    );
                }, 500);
            })
        })
    },
    data() {
        var vx = this;
        return {
            slotData:0,
            isStillLoading : false,
            test: '',
            locationEvents: 0,
            timeOutBatch: '',
            maximalBatch: 40,
            datenow: new Date().getTime(),
            timeGridPlugins: [resourceTimeGridPlugin],
            eventTimeFormat: {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            },
            slotLabelFormat: {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            },
            eventRender: function(info) {
                const data = JSON.parse(info.event.title);
                let jumlah = data.waitingCount;
                let calendarEvent = Vue.extend(CalendarEvent);
                let instance = new calendarEvent({
                    propsData: {
                        xVue: vx.$store,
                        data: data,
                        jumlahWaiting: jumlah,
                        start: moment(info.event.start).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        end: moment(info.event.end).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        hexColor: data.hexColor
                    }
                });
                instance.$mount(info.el.querySelector(".fc-content"));
            },
            showModalHistoryReservasi: false,
            payloadPrint: {
                    printD: false,
                    printSelected: null
                }
        };
    },
    watch: {
        resources(value) {
            var width = 100;
            if (value.length > 2) {
                var widthContainer = $(".day-calendar-appointment").width();
                var widthOneRecource = Math.floor(widthContainer / 2);
                var widthAllResource = widthOneRecource * value.length;
                width = (widthAllResource * 100) / widthContainer ;
            }
        
            $(".day-calendar-appointment .fc-view > table").css(
                "width",
                `${width}%`
            );
        }
    },
    computed: {
        idAppointmentSelect () {
            return this.$store.state.DayCalendarStore.idReservasi
        },
        ...mapState("DayCalendarStore", {
            selectedSlot: state => state.selectedSlot,
            resources: state => state.resources,
            events: state => state.events,
            showModalWaiting: state => state.showModalWaiting,
            showModalInternal: state => state.showModalInternal,
            listWaiting: state => state.listWaiting,
            showModalBlock: state => state.showModalBlock,
            showModalWA: state => state.showModalWA,
            showModalHelp: state => state.showModalHelp,
            showModalTempWa: state => state.showModalTempWa,
            showModalTelekonsul: state => state.showModalTelekonsul,
            showModalMrReg: state => state.showModalMrReg,
            selectedEvent: state => state.selectedEvent,
            showModalHistory: state => state.showModalHistory,
            isLoadFullCalendar: state => state.isLoadFullCalendar,
            isCompleteFullCalendar: state => state.isCompleteFullCalendar,
            detailPanel: (state) => state.detailPanel
        }),
        ...mapState("CallerStore", {
            queueDevice: state => state.queueDevice,
            userNotification: state => state.userNotification,
            showModalPrinter: state => state.showModalPrinter,
            defaultPrinter: state => state.defaultPrinter,
            isLoading: state => state.isLoading
        }),
        ...mapState("DoctorAppointmentStore", {
            dokter: state => state.dokter,
            poli: state => state.poli,
            calendarApi: state => state.calendarApi,
            showModalReservasi: state => state.showModalReservasi
        }),
        nowIndicator() {
            let d = new Date();
            return `${d.getHours()}:${d.getMinutes()}`;
        }
    },
    methods: {
        ...mapMutations({
            setCalendarApi: "DoctorAppointmentStore/SET_CALENDAR_API",
            setSelectedEvent: "DayCalendarStore/SET_SELECTED_EVENT",
            setDokter: "DoctorAppointmentStore/SET_DOKTER",
            setPoli: "DoctorAppointmentStore/SET_POLI",
            setPermissionWhatsapp: "DoctorAppointmentStore/SET_PERMISSION_WHATSAPP",
            // PRINTER
            setDefaultPrinter: "CallerStore/SET_DEFAULT_PRINTER",
            setPrintingOption: "CallerStore/SET_PRINTING_OPTION"
        }),
        ...mapActions({
            getEvent: "DayCalendarStore/GET_EVENT",
            RELOAD_CALENDAR: "DoctorAppointmentStore/RELOAD_CALENDAR",
            saveQueueDevice: "DoctorAppointmentStore/SAVE_QUEUE_DEVICE",
            getSlotEvents: 'DayCalendarStore/GET_SLOT_EVENT'
        }),
        settingQueueDevice() {
            setTimeout(() => {
                const device = { ...this.queueDevice };
                device.queueUnits = [];
                device.dokters =
                    this.dokter != null
                        ? [
                              {
                                  id: `${this.dokter.id}`
                              }
                          ]
                        : [];
                device.notificationId = this.userNotification
                    ? this.userNotification.notificationId
                    : null;
                this.saveQueueDevice(device);
            }, 10);
        },
        toggle() {
            this.$emit("clickToggle", true);
        },
        notif() {
            this.$refs.notif.notif();
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
    },
    mounted() {
        this.setCalendarApi(this.$refs.fullCalendar.getApi());
        this.setPermissionWhatsapp(this.checkPermission('Booking.Whatsapp.Send.Active'))
        this.setSelectedEvent(null);
        // if (localStorage.defaultPrinter) {
        //     this.setDefaultPrinter(localStorage.defaultPrinter);
        // }
        // if (localStorage.printingOption) {
        //     this.setPrintingOption(localStorage.printingOption);
        // }
        if (localStorage.defaultPrinter) {
            this.setDefaultPrinter(localStorage.defaultPrinter);
        }
        if (localStorage.printingOption) {
            this.setPrintingOption(localStorage.printingOption);
        }
        if(!localStorage.defaultPrinter) {
            this.setPrintingOption("DIALOG");
        }
    },
    created() {
        // this.settingQueueDevice();
    }
};
</script>

<style lang="scss" scoped></style>
