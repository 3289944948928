<template>
    <div>
        <div class="form-inner">
            <div class="d-flex justify-content-between align-items-center mt-3" style="margin-bottom: 2.5rem;">
                <span class="mb-0 font-weight-bold" style="font-size: 20px; line-height: 30px; color: #636363;">Cari
                    pasien</span>
                <button class="btn btn-outline-primary"
                    style="font-size: 14px; font-weight: 700; border-radius: 12px; height: 40px;"
                    @click="goToFormPatient"
                    >
                    Tambah pasien
                    <PlusIcon :width="20" :height="20" />
                </button>
            </div>
            <form @submit.prevent="buttonSearch">
                <div class="form-row" style="margin-bottom: 0.5rem;">
                    <div class="col-md-4 mb-3">
                        <input v-model="searchNama" type="text" class="form-control" placeholder="Nama pasien">
                    </div>
                    <div class="col-md-4 mb-3">
                        <div class="input-wrapper">
                            <vue-date-picker
                                v-model="searchDOB"
                                :placeholder="'Tanggal lahir'"
                                :input-class="'form-control'"
                                :maxDate="new Date()"
                                :showDefaultIcon="false"
                                :clearable="false"
                                :disabled-date="disabledDate"
                            >
                                </vue-date-picker>
                            <DateIcon class="icon" :width="16" :height="16" />
                        </div>
                    </div>
                    <div class="col-md-3 mb-3">
                        <input v-model="searchPhone" type="text" class="form-control" placeholder="Nomor telepon">
                    </div>
                    <div class="col-md-1 mb-3">
                        <button v-if="caseData.BUTTON_SEARCH_STATUS === 'IDLE' || caseData.BUTTON_SEARCH_STATUS === 'FOUND'" type="submit"
                            class="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                            >
                            <MagnifierIcon />
                            Cari
                        </button>
                        <button v-else class="btn btn-primary w-100 d-flex align-items-center justify-content-center" style="cursor: not-allowed ;">
                            <span class="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </form>        
            <div class="table-responsive" style="box-shadow: 0px 3px 12px 0px #23415B1A;">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th class="table-style" style="width: 7.72%;">PID</th>
                            <th class="table-style" style="width: 9.43%;">MRN</th>
                            <th class="table-style" style="width: 24.31%;">Nama</th>
                            <th class="table-style" style="width: 8.86%;">Tanggal Lahir</th>
                            <th class="table-style" style="width: 10.65%;">No. Telepon</th>
                            <th class="table-style" style="width: 22.20%;">Email</th>
                            <th class="table-style" style="width: 8.13%;">Jenis Kelamin</th>
                            <th class="table-style" style="width: 7.97%;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="patients.length === 0" :class="{'no-records-row' : true}" >
                            <td colspan="8" class="text-center no-records">No matching records</td>
                        </tr>
                        <tr v-for="(patient, index) in patients" :key="index" :style="{ backgroundColor: index % 2 === 1 ? '#FAFAFA' : '' }">
                        <td class="font-style">{{ patient.id || '-' }}</td>
                        <td>
                            <div v-if="patient.noMedicalRecord" class="mrn-container">
                                <span v-if="patient.isPasienHIS" class="his-label">HIS</span>
                                <span class="mrn-number font-style">{{ patient.noMedicalRecord }}</span>
                            </div>
                            <span v-else class="font-style">-</span>
                        </td>
                        <td class="font-style">{{ patient.nama }}</td>
                        <td class="font-style">{{ patient.tanggalLahir }}</td>
                        <td class="font-style">{{ patient.noTelp }}</td>
                        <td class="font-style">{{ patient.email }}</td>
                        <td class="font-style">{{ $t(patient.jenisKelamin) }}</td>
                        <td class="text-center" style="border-right: none;">
                            <button class="btn btn-sm btn-select" style="line-height: 17.07px;" @click="selectPatient(patient)">Pilih</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            
            <!-- pagination -->
           <Pagination />
        </div>
    </div>
</template>

<script>
import DateIcon from '../../../_icons/DateIcon.vue'
import MagnifierIcon from '../../../_icons/MagnifierIcon.vue'
import Pagination from './Pagination.vue'
import PlusIcon from '../../../_icons/PlusIcon.vue'
import VueDatePicker from './vueDatePick.vue'

export default {
    inject: ['caseData', 'inputSearchNama', 'inputSearchPhone', 'inputSearchDOB', 'buttonSearch', 'goToFormPatient', 'selectedPasien'],
    components: {
        PlusIcon,
        DateIcon,
        MagnifierIcon,
        Pagination,
        VueDatePicker
    },
    computed: {
        patients() {
            return this.caseData.LIST_SEARCH_PASIEN || []
        },
        searchNama: {
            get() {
                return this.caseData.SEARCH_NAMA
            },
            set(val) {
                this.inputSearchNama(val)
            }
        },
        searchDOB: {
            get() {
                return this.caseData.SEARCH_TANGGAL_LAHIR
            },
            set(val) {
                this.inputSearchDOB(val)
            }
        },
        searchPhone: {
            get() {
                return this.caseData.SEARCH_TELP
            },
            set (val) {
                this.inputSearchPhone(val)
            }
        }
    },
    methods: {
        selectPatient (patient) {
            this.selectedPasien(patient)
        },
        disabledDate(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
    }
}
</script>

<style scoped>

.form-control {
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #5578EB;
    border-color: #5578EB;
    height: 40px;
    border-radius: 12px;
}

.btn-outline-primary {
    color: #5578EB;
    border-color: #5578EB;
    background-color: transparent;
}

.table-responsive .table {
    width: 100%;
}

.table-style {
    background-color: #F3F8FF;
    border-right: 1px solid #E4E4E4;
    color: #23415B;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.5px;
    height: 50px;
}

.table-responsive .table thead th.table-style {
    border-top: none;
    border-bottom: none !important;
}

.table-responsive .table thead th.table-style:last-child {
    /* border-right: 1px solid #E4E4E4; */
    border-right: none;
}

.table td,
.table th {
    border-top: 1px solid #E4E4E4;
    border-right: 1px solid #E4E4E4;
    vertical-align: middle;
}

.his-label {
    background-color: #F3F8FF;
    color: #3D3D3D;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.19px;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 5px;
}

.no-records {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  color: #636363;
  padding: 30px 0;
}

.table tbody tr.no-records-row td {
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-top: none;
}

.table tbody tr.no-records-row {
  border: none !important;
}

.btn-outline-primary :hover {
    color: #ffffff;
    background-color: #5578EB;
    border-color: #5578EB;
}
.mrn-container {
    position: relative;

}

.his-label {
    position: absolute;
    top: -15px;
    left: 40px;
    background-color: #FFA726;
    color: #636363;
    font-size: 10px;
    font-weight: 700;
    padding: 1px 4px;
    border-radius: 2px;
}

.mrn-number {
    display: block;
}

.font-style {
    font-size: 13px;
    font-weight: 500;
    line-height: 17.07px;
    color: #636363;
}

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #999;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.input-wrapper {
    position: relative;
}

.btn-select {
            height: 29px;
            color: #636363;
            background-color: #ffffff;
            border: 1px solid #E4E4E4;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
        }

.btn-select:hover {
    background-color: rgba(170, 169, 169, 0.342);
    transform: scale(1.1);
}
</style>