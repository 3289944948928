<template>
    <div>
        <div class="navbar">
            <div class="navbar-inner">
                <div class="navbar-inner-items">
                    <div v-for="step in ['STEP_1', 'STEP_2', 'STEP_3']" :key="step" class="navbar-inner-item"
                        :style="{ backgroundColor: step === CURRENT_STEP ? '#5578EB' : '#E4E4E4' }">
                        <span :style="{ color: step === CURRENT_STEP ? '#ffffff' : '#929292' }">
                            {{ step.slice(-1) }}
                        </span>
                    </div>
                </div>
                <div class="text-center">
                    <div v-if="CURRENT_STEP === 'STEP_1'">
                        <p class="mb-2 font-weight-normal" style="font-size: 20px;">Data Pasien</p>
                        <p class="mb-1 font-weight-normal" style="font-size: 16px; line-height: 24px; color: #929292;">
                            Data Pasien yang
                            akan dibuatkan reservasi. Jika ada perubahan data pasien, dapat diubah langsung
                            melalui</p>
                        <p class="font-weight-normal pointer"
                            style="font-size: 16px; line-height: 24px; text-decoration: underline; color: #5578EB; cursor: pointer;">
                            <router-link :to="{name: 'pasien'}">
                                Halaman pasien
                            </router-link>
                        </p>
                    </div>
                    <div v-else-if="CURRENT_STEP === 'STEP_2'">
                        <p class="mb-2 font-weight-normal" style="font-size: 20px;">Reservasi</p>
                        <p class="mb-1 font-weight-normal" style="font-size: 16px; line-height: 24px; color: #929292;">
                            Buat reservasi
                            dengan jadwal dokter yang sesuai</p>
                    </div>
                    <div v-else-if="CURRENT_STEP === 'STEP_3'">
                        <p class="mb-2 font-weight-normal" style="font-size: 20px;">Tambahan</p>
                        <p class="mb-1 font-weight-normal" style="font-size: 16px; line-height: 24px; color: #929292;">
                            Pilih penanggung
                            jawab pembayaran (wajib) dan pesan (opsional)</p>
                    </div>
                </div>
            </div>
            <div v-if="CURRENT_STEP === 'STEP_2'" class="d-flex ml-auto">
                <button class="btn btn-outline-primary"
                    @click="openModalBantuan"
                    style="font-size: 14px; font-weight: 700; border-radius: 12px; height: 40px;">
                    Butuh Bantuan ?
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['CURRENT_STEP', 'openModalBantuan']
}
</script>


<style scoped>

.navbar {
    border-bottom: 1px solid #eeeef4;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 1rem;
}


.navbar-inner {
    padding: 2rem 30rem 0.5rem; 
}

@media (max-width: 1398px) {
    .navbar-inner {
        padding: 2rem 10rem 0.5rem; 
    }
}


.navbar-inner-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    position: relative;
}

.navbar-inner-item{
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 1rem 1rem;
    font-weight: 500;
    position: relative;
    z-index: 2;
}

.navbar-inner-item::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -2rem;
    right: 2.5rem;
    height: 2px;
    background-image: linear-gradient(to right, #ccc 50%, transparent 50%);
    background-size: 10px 2px;
    background-repeat: repeat-x;
    z-index: 1;
}

.navbar-inner-item:first-child::before {
    display: none;
}

</style>