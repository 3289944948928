<template>
    <div v-if="showPagination">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }" style="width: 32px;">
                    <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">
                        <ChevronLeft />
                    </a>
                </li>
                <template v-for="(page,  index) in displayedPages">
                    <li v-if="page !== '...'" :key="page" class="page-item" :class="{ active: page === currentPage }">
                        <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                    </li>
                    <li v-else :key="`ellipsis-key-${index}` + page" class="page-item disabled">
                        <a class="page-link" href="#">...</a>
                    </li>
                </template>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }" style="width: 32px;">
                    <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">
                        <ChevronRight />
                    </a>
                </li>
            </ul>
        </nav>
        <div class="text-center mt-2 mb-2" style="color: #3D3D3D; font-size: 12px; font-weight: 500;">
            Menampilkan {{ startItem }}-{{ endItem }} dari {{ totalItems }} data
        </div>
    </div>
</template>

<script>
import ChevronLeft from '../../../_icons/ChevronLeft.vue';
import ChevronRight from '../../../_icons/ChevronRight.vue';

export default {
    inject: ['caseData', 'pageActive'],
    components: {
        ChevronLeft,
        ChevronRight
    },
    computed: {
        showPagination() {
            return this.caseData.PAGE_ACTIVE && this.caseData.TOTAL_PAGE && this.caseData.TOTAL_LIST;
        },
        currentPage() {
            return this.caseData.PAGE_ACTIVE || 1;
        },
        totalPages() {
            return this.caseData.TOTAL_PAGE || 1;
        },
        totalItems() {
            return this.caseData.TOTAL_LIST || 0;
        },
        displayedPages() {
            const pages = [];
            const totalPages = this.totalPages;
            const currentPage = this.currentPage;

            pages.push(1);

            if (currentPage > 4) {
                pages.push('...');
            }

            let rangeStart = Math.max(2, currentPage - 2);
            let rangeEnd = Math.min(totalPages - 1, currentPage + 2);

            if (currentPage <= 4) {
                rangeEnd = Math.min(7, totalPages - 1);
            } else if (currentPage > totalPages - 4) {
                rangeStart = Math.max(totalPages - 6, 2);
            }

            for (let i = rangeStart; i <= rangeEnd; i++) {
                pages.push(i);
            }

            if (rangeEnd < totalPages - 1) {
                pages.push('...');
            }

            if (totalPages > 1 && !pages.includes(totalPages)) {
                pages.push(totalPages);
            }

            return pages;
        },
        startItem() {
            return (this.currentPage - 1) * this.caseData.PAGE_SIZE + 1;
        },
        endItem() {
            return Math.min(this.currentPage * this.caseData.PAGE_SIZE, this.totalItems);
        }
    },
    methods: {
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                // console.log(`Changing to page ${page}`);
                this.pageActive(page)
            }
        }
    }
}
</script>

<style scoped>
.pagination .page-link {
    color: #19558C;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    line-height: 1;
    border-radius: 6px;
}

.pagination .page-item.active .page-link {
    background-color: #5578EB;
    border-color: #5578EB;
    border-radius: 6px;
    border: 1px solid #E4E4E4
}

.pagination .page-item.disabled .page-link {
    color: #19558C;
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid #E4E4E4
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
    width: auto;
    padding: 0 5px;
    border-radius: 6px;
}

.pagination svg {
    vertical-align: middle;
}
</style>