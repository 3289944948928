<template>
    <div class="modal-footer border-1" :class="{ 'justify-content-end': !showPrevious, 'justify-content-between' : showPrevious }"
        style="padding: 1.25rem; display: flex; align-items: center;">
        <button v-if="showPrevious" type="button" class="btn btn-previous"
            @click="backContent"
        >
            <ChevronLeft :strokeColor="'#5578EB'"/>
            Sebelumnya
        </button>
        <div>
            <button v-if="showCancel" type="button" class="btn btn-cancel" @click="closeModal">Batal</button>
            <button 
                v-if="showNext"
                type="button" 
                class="btn btn-next"
                :class="{ 'btn-complete': isFormComplete, 'btn-incomplete': !isFormComplete }"
                :disabled="!isFormComplete"
                @click="nextContent"
            >
                Selanjutnya
            </button>
        </div>
    </div>
</template>

<script>
import ChevronLeft from '../../../_icons/ChevronLeft.vue'

export default {
    inject: ['closeModal'],
    methods: {
        nextContent() {
            this.$emit('nextContent', true)
        },
        backContent () {
            this.$emit('backContent', true)
        }
    },
    props: {
        showPrevious: {
            type: Boolean,
            default: false
        },
        showCancel: {
            type: Boolean,
            default: false
        },
        showNext: {
            type: Boolean,
            default: false
        },
        isFormComplete: {
            type: Boolean,
            required: true
        }
    },
    components: {
        ChevronLeft
    }
}
</script>

<style scoped>

.btn {
    font-size: 14px;
    border-radius: 12px;
    width: 155px;
    height: 40px;
    font-weight: 700;
}

.btn-previous {
    background: transparent;
    color: #5578EB;
    border: 1px solid #5578EB;
}

.btn-cancel {
    background: transparent;
    color: #5578EB;
    margin-right: 0.5rem;
    font-size: 14px; 
    font-weight: 700;
}

.btn-next {
    color: #ffffff;
}

.btn-complete {
    background: #5578EB;
    cursor: pointer;
}

.btn-incomplete {
    background: #C1C1C1;
    cursor: not-allowed;
}
</style>