<template>
    <div>
        <div class="reservation-form">
            <div class="row">
                <div class="col-md-6" style="padding-left: 2rem; padding-right: 0; padding-top: 1rem;">
                    <p class="mb-0 font-weight-bold"
                        style="font-size: 20px; line-height: 30px; color: #636363; padding-bottom: 1rem;">Reservasi</p>
                    <div style="padding-left: 3rem; height: 450px; overflow-y: auto; ;">
                        <div class="form-group pr-4">
                            <label for="visitDate" style="font-size: 14px; color: #636363; font-weight: 600;">Pilih Tanggal
                                Kunjungan<span style="color: #C2283C;">*</span></label>
                            <div class="input-wraper">
                                <vue-date-picker
                                    v-model="selectedDate"
                                    :placeholder="'Pilih tanggal'"
                                    :input-class="'form-control'"
                                    :showDefaultIcon="false"
                                    :clearable="false"
                                >
                                </vue-date-picker>
                                <DateIcon class="icon" :width="16" :height="16" :strokeColor="'#5578EB'"/>
                            </div>
                            <small v-if="!caseData.STATUS_DATE" class="form-text text-danger">Tanggal minimal hari ini</small>
                        </div>
                        <div class="form-group pr-4 mb-2">
                            <label for="doctorSelect" style="font-size: 14px; color: #636363; font-weight: 600;">Pilih
                                Dokter <span style="color: #C2283C;">*</span></label>
                            <MultiSelect
                                :model-value="selectedDoctors"
                                :list-options="caseData.IS_SPESIALIS ?caseData.LIST_DOKTER_GROUP : caseData.LIST_DOKTER"
                                :label="'nama'"
                                :internal-search="true"
                                :show-caret="true"
                                :multiple="true"
                                :track-by="caseData.IS_SPESIALIS ? 'id' : 'id'"
                                :enable-grouping="caseData.IS_SPESIALIS"
                                :group-values="caseData.IS_SPESIALIS ? 'doctors' : undefined"
                                :group-label="caseData.IS_SPESIALIS ? 'nama' : undefined"
                                :is-loading="caseData.STATUS_LIST_DOKTER == 'LOADING'"
                                @select="onSelect"
                                @remove="onRemove"
                            >
                            </MultiSelect>
                            <small v-if="caseData.STATUS_SELECTED_DOKTER == 'EMPTY'" class="form-text text-danger">*Silakan pilih dokter terlebih dahulu</small>
                        </div>
                        <div class="form-check mb-3">
                            <input class="form-check-input" type="checkbox" id="specialistCheck" v-model="isSpesialis">
                            <label class="form-check-label" for="specialistCheck" style="font-size: 14px; color: #636363;">
                                Dengan spesialis
                            </label>
                        </div>
                        <div class="form-group">
                            <label style="font-size: 14px; color: #636363; font-weight: 600;">Pilihan Metode Konsultasi Dokter:<span style="color: #C2283C;">*</span></label>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="consultationMethod" id="inPerson" value="TATAP_MUKA" v-model="modeConsul">
                                <label class="form-check-label" for="inPerson" style="font-size: 14px; color: #636363;">
                                    Datang Langsung (default)
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="consultationMethod" id="teleconsultation" value="TELEKONSUL" v-model="modeConsul">
                                <label class="form-check-label" for="teleconsultation" style="font-size: 14px; color: #636363;">
                                    Telekonsultasi
                                </label>
                            </div>
                        </div>
                        <div v-if="caseData.MODE_KONSULTASI === 'TELEKONSUL' && caseData.STATUS_SELECTED_SLOT" class="form-group">
                            <label style="font-size: 14px; color: #636363; font-weight: 600;">Pilih opsi kirim link pembayaran:<span style="color: #C2283C;">*</span></label>
                            <div class="form-check mb-2">
                                <input class="form-check-input" type="checkbox" id="mobileCheck" v-model="checkBoxMobile">
                                <label class="form-check-label" for="mobileCheck" style="font-size: 14px; color: #636363;">
                                    Mobile
                                </label>
                            </div>
                            <div class="form-check mb-2 d-flex align-items-center">
                                <input class="form-check-input" type="checkbox" id="smsCheck" v-model="checkBoxSms">
                                <label class="form-check-label mr-2" for="smsCheck" style="font-size: 14px; color: #636363; width: 50px;">
                                    SMS
                                </label>
                                <input type="text" :disabled="!caseData.SELECTED_SMS" class="form-control" v-model="inputSms" style="width: 218px; border-radius: 6px; border: 1px solid #E4E4E4" placeholder="+62">
                            </div>
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input" type="checkbox" id="emailCheck" v-model="checkBoxEmail">
                                <label class="form-check-label mr-2" for="emailCheck" style="font-size: 14px; color: #636363; width: 50px;">
                                    Email
                                </label>
                                <input type="email" :disabled="!caseData.SELECTED_EMAIL" class="form-control" v-model="inputEmail" style="width: 218px; border-radius: 6px; border: 1px solid #E4E4E4" placeholder="Email">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- right column -->
                <div class="col-md-6" v-if="caseData.STATUS_SELECTED_DOKTER == 'SELECTED'">
                    <div class="appointment-schedule d-flex flex-column" style="padding-left: 2rem; height: 100%;">
                        <div class="fixed-content">
                            <p class="selected-schedule-title">Jadwal terpilih<span class="text-danger">*</span></p>
                            <template v-if="!caseData.STATUS_SELECTED_SLOT">
                                <p class="form-text text-danger select-schedule-prompt">*Silakan pilih jadwal terlebih dahulu</p>
                            </template>
                            
                            <!-- selected-dokter -->
                            <div v-if="caseData.STATUS_SELECTED_SLOT" >
                                <p class="selected-slot-info">{{ caseData.SELECTED_SLOT.nama }}</p>
                                <p class="selected-slot-info">{{ caseData.SELECTED_SLOT.jamMulai }} - {{ caseData.SELECTED_SLOT.jamSelesai }}</p>

                                <div v-if="caseData.MODE_KONSULTASI === 'TELEKONSUL'">
                                    <label class="pricing-label">Pricing</label>
                                    <div class="d-flex align-items-center pb-2">
                                        <input
                                            type="text"
                                            class="form-control price-input"
                                            id="price"
                                            :placeholder="formattedPrice"
                                            :value="formattedPrice"
                                            :readonly="!caseData.UPDATE_PRICE"
                                            :class="{'editable': caseData.UPDATE_PRICE}"
                                            :style="{ backgroundColor: caseData.UPDATE_PRICE ? '#FFFFFF' : '#F5F5F5' }"
                                            @input="updatePrice"   
                                        >
                                        <button
                                            v-if="showEditButton"
                                            @click="buttonEdit"
                                            class="btn font-weight-bold edit-button"
                                        >
                                            {{ caseData.UPDATE_PRICE ? 'Save' : 'Edit' }}
                                            <component :is="caseData.UPDATE_PRICE ? 'SaveIcon' : 'EditIcons'" />
                                        </button>
                                    </div>
                                    <p v-if="caseData.UPDATE_PRICE" class="update-message">Tarif di atas akan disimpan ketika membuat appointment selanjutnya</p>
                                </div>
                            </div>
                        </div>
                        <div class="scrollable-cards mt-4 flex-grow-1 overflow-auto">
                            <JadwalDokter />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <ModalHelp v-if="caseData.MODAL_HELP" @closeModal="closeModalBantuan"/>
        <Footer 
            :show-previous="true"
            :show-cancel="true"
            :show-next="true"
            :is-form-complete="isFormComplete"
            @nextContent="nextStepContent"
            @backContent="backStepContent"
        />
    </div>
</template>

<script>
import DateIcon from '../../../_icons/DateIcon.vue'
import EditIcons from '../../../_icons/EditIcons.vue';
import SaveIcon from '../../../_icons/SaveIcon.vue';
import JadwalDokter from './CardJadwalDokter.vue';
import MultiSelect from '../../../_select/MultiSelect.vue'
import VueDatePicker from './vueDatePick.vue'
import ModalHelp from '../ButuhBantuan.vue'
import Footer from './Footer.vue'

export default {
    inject: ['caseData', 'setCurrentStep', 'setDokterSelected', 'setSpesialis', 'setModeConsul', 'selectedDateReservasi', 'inputSmsPasien', 'selectedCheckBox', 'closeModalBantuan', 'updatedPrice', 'savePrice', 'editPrice'],
    components: {
        DateIcon,
        EditIcons,
        SaveIcon,
        JadwalDokter,
        MultiSelect,
        VueDatePicker,
        ModalHelp,
        Footer
    },
    // data() {
    //     return {
    //         selectedDoctors: []
    //     }
    // },
    computed: {
        price: {
            get () {
                return this.caseData.SELECTED_SLOT.price
            },
            set (val) {
                this.updatedPrice(val)
            }
        },
        checkBoxEmail: {
            get() {
                return this.caseData.SELECTED_EMAIL
            },
            set (val) {
                this.selectedCheckBox({key: 'SELECTED_EMAIL', value: val})
            }
        },
        checkBoxSms: {
            get () {
                return this.caseData.SELECTED_SMS
            },
            set (val) {
                this.selectedCheckBox({key: 'SELECTED_SMS', value: val})
            }
        },
        checkBoxMobile: {
            get() {
                return this.caseData.SELECTED_MOBILE_APP
            },
            set (val) {
                this.selectedCheckBox({key: 'SELECTED_MOBILE_APP', value: val})
            }
        },
        inputEmail: {
            get() {
                return this.caseData.DETAIL_PASIEN.EMAIL
            },
            set(val) {
                this.inputSmsPasien({key: 'EMAIL', value: val})
            }
        },
        inputSms: {
            get() {
                return this.caseData.DETAIL_PASIEN.SMS
            },
            set(val) {
                this.inputSmsPasien({key: 'SMS', value: val})
            }
        },
        selectedDate: {
            get() {
                return this.caseData.DATE
            },
            set(val) {
                this.selectedDateReservasi(val)
            }
        },
        modeConsul: {
            get () {
                return this.caseData.MODE_KONSULTASI
            },
            set (val) {
                this.setModeConsul(val)
            }
        },
        isSpesialis: {
            get() {
                return this.caseData.IS_SPESIALIS
            },
            set(val) {
                this.setSpesialis(val)
            }
        },
        selectedDoctors() {
                return this.caseData.SELECTED_DOKTER ?? []
        },
        currentDoctorsList() {
            return this.caseData.IS_SPESIALIS ? this.groupedDoctors: this.ungroupedDoctors
        },
        isFormComplete() {
            if(this.caseData.STATUS_SELECTED_SLOT && this.caseData.STATUS_DATE && (
                this.caseData.MODE_KONSULTASI == "TATAP_MUKA" || 
                (this.caseData.MODE_KONSULTASI == "TELEKONSUL" && 
                !this.caseData.UPDATE_PRICE &&
                this.caseData.FORM_RADIO_STATUS)
            )) {
                return true
            }
            return false
        },
        showEditButton() {
            return this.caseData.UPDATE_PRICE || this.caseData.PERMISSION_UPDATE_PRICE
        },
        formattedPrice() {
            return this.formatPrice(this.price)
        }
    },
    methods: {
        onSelect(selectedOptions) {
            const selected = structuredClone(this.selectedDoctors)
            if(isArray(selectedOptions)) {
                selected.push(...selectedOptions)
            } else {
                selected.push(selectedOptions)
            }
            this.setDokterSelected(selected)
            
        },
        onRemove(selectedOption) {
            const selected = structuredClone(this.selectedDoctors)
            let afterSelected = []
            if (isArray(selectedOption)) {
                afterSelected = selected.filter(_item => !selectedOption.some(_item2 => _item2.id == _item.id))
            } else {
                afterSelected = selected.filter(_item => _item.id != selectedOption.id)
            }
            this.setDokterSelected(afterSelected)
        },
        nextStepContent() {
            this.setCurrentStep('STEP_3')
        },
        backStepContent() {
            this.setCurrentStep('STEP_1')
        },
        disabledDate(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        buttonEdit() {
            if(!this.caseData.UPDATE_PRICE) {
                this.editPrice()
            } else {
                this.savePrice()
            }
            // function for button edit
        }, 
        formatPrice(price) {
           if(!price && typeof(price) != "number") return '';
           let priceString = price.toString().split('').reverse().join('');
           let formattedPrice = priceString.match(/.{1,3}/g).join(',');
           return formattedPrice.split('').reverse().join('');
        },
        updatePrice(event) {
            const rawValue = event.target.value.replace(/\D/g,'');
            this.price = rawValue
        }
    },

    mounted() {
        this.selectedDokter = this.caseData.SELECTED_DOKTER;
    }
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
    width: 200px;
    height: 40px;
}

.scrollable-cards {
    min-height: 100px;
}

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #999;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.appointment-schedule {
    background-color: #FAFAFA ;
    padding: 1rem 2rem 0;
    overflow: hidden;
    max-height: 505px;
}

.input-wraper {
   position: relative;
}

.price-input {
  border: 1px solid #E4E4E4;
  width: 200px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  cursor: not-allowed;
}

.price-input.editable {
  cursor: text;
}

.edit-button {
  color: #5578EB;
  border-radius: 10px;
  font-size: 14px;
}

.update-message {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  color: #C2283C;
}

.selected-schedule-title {
  font-size: 14px;
  color: #636363;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.select-schedule-prompt {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
}

.selected-slot-info {
  font-size: 14px;
  color: #636363;
  font-weight: 500;
  line-height: 19.5px;
  margin-bottom: 0;
}

.pricing-label {
  font-size: 14px;
  color: #636363;
  font-weight: 600;
  padding-top: 1rem;
}

</style>