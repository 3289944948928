<template>
  <svg
    :class="[`chevron-${position}`, 'chevron-icon']"
    :width="width"
    :height="height"
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.999998L1 11L11 21"
      :stroke="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    position: { 
      type: String, 
      default: 'left' 
    },
    width: { 
      type: Number, 
      default: 12 
    },
    height: { 
      type: Number, 
      default: 22 
    },
    strokeColor: { 
      type: String, 
      default: "#929292" 
    },
  }
};
</script>

<style scoped>
.chevron-icon {
  transition: transform 0.2s ease;
}
.chevron-right {
  transform: rotate(180deg);
}
.chevron-up {
  transform: rotate(90deg);
}
.chevron-down {
  transform: rotate(270deg);
}
</style>