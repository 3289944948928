<template>
    <div class="modal show d-block" tabindex="-1" style="overflow-y: auto;">
        <load-spinner v-if="isLoadSpinner"/>
        <template v-else>
            <modal-reservasi :data-reservasi="dataDefault"/>
        </template>
    </div>
</template>

<script>
import LoadSpinner from '../../_general/LoadSpinner.vue'
import ModalReservasi from './ModalReservasi.vue'
import MapperData from './mapper/mapperModalReservasi'
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
import {  mapMutations } from "vuex";

export default {
    components: { LoadSpinner, ModalReservasi },
    provide() {
        return {
            closeModal: () => {
                this.closeModal(false)
            }
        }
    },
    data () {
        return {
            isLoadSpinner: true,
            dataDefault: {}
        }
    },
    props: {
        dataSlot: {
            type: Object,
            default: () => {}
        }
    },
    async mounted () {
        this.dataDefault = MapperData.mapperInitialData(this.dataSlot)
        if(this.dataSlot && this.dataSlot.generatedId) {
            const { data } = await ReservationRepository.getReservationAppointmentBackoffice(
                this.dataSlot.generatedId
            );
        this.dataDefault = MapperData.mapperGetReservasi(data, this.dataDefault)
        }
        this.isLoadSpinner = false
    },
    methods: {
        ...mapMutations({
            closeModal: "DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI",
        }),
    }
}
</script>

<style>

</style>