<template>
    <div class="modal show d-block" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered" style="min-width: 658px;">
            <div class="modal-content px-2">
                <div class="modal-body">
                    <div class="row" v-for="(item, index) in faqItems" :key="index" style="margin-bottom: 2rem;">
                        <div class="col-6">
                            <div :class="['status-indicator', item.class]">
                                {{ item.label }}
                            </div>
                        </div>
                        <div class="col-6">
                            <span class="description">{{ item.description }}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary mt-3" @click="$emit('closeModal', true)">Tutup</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            faqItems: [
                { label: 'HH:MM - HH:MM Patient/Slot', class: 'available', description: 'Jadwal dokter tersedia' },
                { label: 'HH:MM - HH:MM Patient/Slot', class: 'selected', description: 'Jadwal dokter tersedia dan sedang terpilih' },
                { label: 'HH:MM - HH:MM', class: 'blocked', description: 'Jadwal dokter diblok' },
                { label: 'HH:MM - HH:MM Patient/Slot', class: 'filled', description: 'Jadwal dokter sudah terisi' },
                { label: 'Waiting List', class: 'waiting-list', description: 'Jadwal dokter sudah penuh, maka akan didaftarkan sebagai "Waiting List"' },
            ]
        }
    },
}

</script>

<style scoped>

.modal-body {
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.description {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    color: #636363;
}

.status-indicator {
    padding: 7px 8px;
    border-radius: 20px;
    width: 199px;
    height: 32px;
    font-size: 0.9em;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
}

.available {
    border: 1px solid #5A69DD;
    background-color: #FFFFFF;
    text-align: center;
}

.selected {
    border: 1px solid #5A69DD;
    background-color: #5A69DD;
    color: #FFFFFF;
    text-align: center;

}

.blocked {
    background: var(--Grayscale-Gray-F, #EFEFEF);
    color: #929292;
    text-decoration: line-through;
    text-align: center;
    cursor: not-allowed;
}

.filled {
    background: var(--Grayscale-Gray-F, #EFEFEF);
    color: #929292;
    text-align: center;
    cursor: not-allowed;
}

.waiting-list {
    border: 1px solid #5A69DD;
    background-color: #FFFFFF;
    text-align: center;
}

.btn-primary {
    width: 92px;
    border-radius: 12px;
    background: #5578EB;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
}
</style>