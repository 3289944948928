var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.caseData.SLOT_WITH_SELECTED),function(doctor,index){return _c('div',{key:_vm.generateUniqueDoctorKey(doctor, index),staticClass:"card card-style"},[_c('div',{staticClass:"card-header d-flex justify-content-between align-items-center"},[_c('p',{staticClass:"text-start mb-0"},[_vm._v(_vm._s(doctor.dokter))]),(_vm.caseData.MODE_KONSULTASI === 'TELEKONSUL')?_c('p',{staticClass:"price mb-0"},[_vm._v(_vm._s(_vm.formatPrice(doctor.PRICE)))]):_vm._e()]),(doctor.IS_LOADING_RESERVASI)?_c('div',{staticClass:"d-flex flex-row align-items-start p-2"},[_vm._m(0,true),_c('p',{staticClass:"text-primary ml-4 mt-2"},[_vm._v("Loading...")])]):_vm._e(),(doctor.STATUS_JADWAL)?[(_vm.caseData.MODE_KONSULTASI === 'TELEKONSUL' && !doctor.MODE_TELEKONSUL)?[_vm._m(1,true)]:_vm._l((doctor.jadwals),function(schedule,scheduleIndex){return _c('div',{key:_vm.generateUniqueScheduleKey(doctor, schedule, scheduleIndex ),staticClass:"card-body"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(schedule.textJadwal))]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((schedule.slots),function(slot,slotIndex){return _c('button',{key:_vm.generateUniqueSlotKey(doctor, schedule, slot, slotIndex),staticClass:"mb-2 btn",class:{
                    'btn-outline-primary' : slot.type === 'EMPTY',
                    'btn-disabled' : slot.type === 'RESERVED',
                    'btn-selected' : _vm.isSelectedSlot(doctor, slot),
                    'btn-blocked'  : slot.type === 'BLOCKED'
                },style:({
                    cursor: slot.type === 'EMPTY' ? 'pointer' : 'not-allowed',
                    textDecoration: slot.type === 'BLOCKED' ? 'line-through' : 'none'
                    }),attrs:{"disabled":slot.type !== 'EMPTY'},on:{"click":function($event){return _vm.selectSlot(doctor , schedule, slot)}}},[(slot.type === 'BLOCKED')?[_c('span',{staticClass:"blocked-text"},[_vm._v(_vm._s(slot.text))])]:[_vm._v(" "+_vm._s(slot.text)+" ")]],2)}),0)])})]:_vm._e(),(!doctor.STATUS_JADWAL && !doctor.IS_LOADING_RESERVASI)?[_vm._m(2,true)]:_vm._e()],2)}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border text-primary mt-2 ml-2",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-status"},[_vm._v("Dokter tidak melayani telekonsultasi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-status"},[_vm._v("Dokter tidak memiliki jadwal")])])
}]

export { render, staticRenderFns }