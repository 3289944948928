<template>
<div style="min-height: 469px;">
    <div class="d-flex justify-content-between align-items-center mt-3" style="margin-bottom: 2.5rem;">
        <span class="mb-0 font-weight-bold" style="font-size: 20px; line-height: 30px; color: #636363;">Data
            pasien</span>
        <button class="btn btn-outline-primary"
            style="font-size: 14px; font-weight: 700; border-radius: 12px; height: 40px;"
            @click="goToSearchPatient"
            >
            Cari pasien
            <MagnifierIcon :width="20" :height="20" :strokeColor="'#5578EB'"/>
        </button>
    </div>
    <div class="patient-data">
        <div class="row mb-3">
            <div class="col-3">
                <p class="mb-0" style="color: #929292; font-size: 14px; font-weight: 500;">Nama
                </p>
            </div>
            <div class="col-9">
                <p class="mb-0" style="color: #636363; font-size: 14px; font-weight: 600;">{{ caseData.SELECTED_PASIEN.nama }}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <p class="mb-0" style="color: #929292; font-size: 14px; font-weight: 500;">
                    Tanggal lahir</p>
            </div>
            <div class="col-9">
                <p class="mb-0" style="color: #636363; font-size: 14px; font-weight: 600;">{{ caseData.SELECTED_PASIEN.tanggalLahir }}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <p class="mb-0" style="color: #929292; font-size: 14px; font-weight: 500;">Jenis
                    kelamin</p>
            </div>
            <div class="col-9">
                <p class="mb-0" style="color: #636363; font-size: 14px; font-weight: 600;">{{ $t(caseData.SELECTED_PASIEN.jenisKelamin) }}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <p class="mb-0" style="color: #929292; font-size: 14px; font-weight: 500;">No.
                    Telepon</p>
            </div>
            <div class="col-9">
                <p class="mb-0" style="color: #636363; font-size: 14px; font-weight: 600;">{{ caseData.SELECTED_PASIEN.noTelp }}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <p class="mb-0" style="color: #929292; font-size: 14px; font-weight: 500;">Email
                </p>
            </div>
            <div class="col-9">
                <p class="mb-0" style="color: #636363; font-size: 14px; font-weight: 600;">{{ caseData.SELECTED_PASIEN.email }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <p class="mb-0" style="color: #929292; font-size: 14px; font-weight: 500;">No.
                    Rekam Medik</p>
            </div>
            <div class="col-9">
                <p class="mb-0" style="color: #636363; font-size: 14px; font-weight: 600;">{{ caseData.SELECTED_PASIEN.noMedicalRecord }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import MagnifierIcon from '../../../_icons/MagnifierIcon.vue';

export default {
    inject: ['caseData', 'goToSearchPatient'],
    components: {
        MagnifierIcon
    }
}

</script>

<style scoped>
 .patient-data {
     padding-left: 3rem;
 }

 .patient-data .row {
     align-items: center;
 }

 .patient-data .col-3 {
     padding-right: 0;
 }

 .patient-data .col-9 {
     padding-left: 0;
 }
</style>