<template>
    <div :class="['field-date-picker', {'hide-icon': !showDefaultIcon}]">
        <date-picker
            v-model="selectedDate"
            :type="type" 
            :format="format"
            :input-class="classControl"
            :value-type="valueType"
            :placeholder="placeholder"
            :disabled-date="disabledDate"
            :range="range"
            :min-date="minDate"
            :max-date="maxDate"
            :clearable="clearable"
            :shortcuts="shortcutButton"
            @selected="onDateSelected"
        />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'

export default {
    components: {
        DatePicker
    },
    data() {
        return {
            shortcutToday: [{
                text: 'Today',
                onClick() {
                    const date = new Date();
                    return date;
                }
            }],
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: [Array, Number, String, Object, Date]
        },
        format: {
            type: String,
            default: 'DD-MM-YYYY'
        },
        valueType: {
            type:String,
            default:'YYYY-MM-DD'
        },
        placeholder: {
            type: String,
            default: ''
        },
        inputClass:{
            type: String,
            default: 'form-control'
        },
        customClass: [String, Array, Function, Object],
        type: {
            type:String,
            default:'date'
        },
        disabledDate: {
            type: [String, Function]
        },
        range: {
            type:Boolean,
            default: false
        },
        minDate: {
            type: Date,
            default: null
        },
        maxDate: {
            type: Date,
            default: null
        },
        clearable: {
            type: Boolean,
            default: true
        },
        showDefaultIcon: {
            type: Boolean,
            default: true
        },
        buttonToday: {
            type: Boolean,
            default: false
        },
        shortcuts: {
            type: Array,
            default: ()=>[]
        },
        
    },
    computed: {
        selectedDate: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('change', newValue)
            }
        },
        shortcutButton: function(){
            var data = [];
            if(this.buttonToday){
                data = [...data,... this.shortcutToday];
            }
            return [...data,...this.shortcuts];
        },
        classControl: function(){
            if(this.customClass != undefined || this.customClass != null){
                return this.customClass
            } else {
                return this.errors.has(this.errorField) ? this.errorClass : this.inputClass;
            }
        }
    },

    methods: {
        onDateSelected(date) {
            this.$emit('date-selected', date)
        }
    }
}
</script>

<style scoped>

.field-date-picker.hide-icon .mx-input-wrapper .mx-icon-calendar {
    display: none !important;
}
</style>
