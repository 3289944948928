<template>
    <div>
        <base-header base-title="Dokter Appointment"></base-header>
        <!-- begin:: Content -->
        <fullscreen ref="fullscreen" @change="fullscreenChange">
            <div
                class="kt-content kt-grid__item kt-grid__item--fluid"
                id="kt_content"
            >
                <div class="kt-portlet kt-portlet--mobile">
                    <div
                        class="kt-portlet__body"
                        ref="bodyData"
                        style="min-height: 100vh;"
                    >
                        <doctor-appointment-control id="doctor-appointment-control"></doctor-appointment-control>
                        <div class="calendar-container">
                            <month-calendar
                                v-if="type == 1"
                                @clickToggle="toggle"
                            ></month-calendar>
                            <day-calendar
                                v-if="type == 2"
                                @clickToggle="toggle"
                                ref="notif"
                            ></day-calendar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end:: Content -->
        </fullscreen>
    </div>
</template>

<script>
import BaseHeader from "../_base/BaseHeader";
// import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
// const DoctorRepository = RepositoryFactory.get("doctor");
// const TujuanRujukanRepository = RepositoryFactory.get("tujuanRujukan");
// const ReservasiRepository = RepositoryFactory.get("reservation");
import DayCalendar from "./DayCalendar/DayCalendar.vue";
import DoctorAppointmentControl from "./_components/DoctorAppointmentControl";
import MonthCalendar from "./MonthCalendar/MonthCalendar";
import { mapState, mapMutations, mapActions } from "vuex";
// import QueueDevice from "../../model/queue-device-model";
import Fullscreen from "vue-fullscreen/src/component.vue";

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { fromEventPattern } from "rxjs";
import { debounceTime } from "rxjs/operators";


// import { uuid } from "vue-uuid";

import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();

export default {
    components: {
        MonthCalendar,
        DayCalendar,
        BaseHeader,
        DoctorAppointmentControl,
        Fullscreen
    },
    data() {
        return {
            fullscreen: false,
            iniPesan: "",
            pesanBaru: "",
            countDown: 10,
            timeOutGlobal: null
        };
    },
    computed: {
        ...mapState("DoctorAppointmentStore", {
            type: state => state.type,
            stompClient: state => state.stompClient,
            date: state => state.date,
            dokter: state => state.dokter,
            doctorLogin: state => state.doctorLogin,
            dokterArray: state => state.dokterArray
        }),
        ...mapState("CallerStore", {
            userNotification: state => state.userNotification,
            sessionId: state => state.sessionId
        }),
        ...mapState("DayCalendarStore", {
            selectedJadwal: state => state.selectedJadwal,
            events: state => state.events
        })
    },
    methods: {
        ...mapActions({
            // getDataFromApi: "DoctorAppointmentStore/GET_DATA_FROM_API",
            getDokterDropdownData: "DoctorAppointmentStore/getDokterDropdownData",
            // saveUserNotification: "CallerStore/POST_USER_NOTIFICATION",
            reloadCalendar: "DoctorAppointmentStore/RELOAD_CALENDAR",
            // SOCK_MSG: "DoctorAppointmentStore/SOCK_MSG",
            // saveQueueDevice: "DoctorAppointmentStore/SAVE_QUEUE_DEVICE",
            getCurrentDoctor: "DoctorAppointmentStore/GET_CURRENT_DOCTOR",
            getSpecialtiesDropdownData: "DoctorAppointmentStore/getSpecialtiesDropdownData"
        }),
        ...mapMutations({
            SET_STOMP_CLIENT: "DoctorAppointmentStore/SET_STOMP_CLIENT",
            // setSessionId: "CallerStore/SET_SESSION_ID",
            setDoctorLogin: "DoctorAppointmentStore/SET_DOCTOR_LOGIN",
            setShowModalHistory: "DayCalendarStore/SET_SHOW_MODAL_HISTORY",
            // resetListDokter: "DoctorAppointmentStore/RESET_LIST_DOKTER",
        }),
        toggle() {
            this.$refs.fullscreen.toggle();
        },
        fullscreenChange(isFullScreen) {
            this.fullscreen = isFullScreen;
        },
        // async onInit() {
        //     // set session id & queueId
        //     let sessionId = uuid.v4();
        //     if (sessionStorage.getItem("sessionId") != undefined) {
        //         sessionId = sessionStorage.getItem("sessionId");
        //     }
        //     //   set queueDevice
        //     let device =
        //         sessionStorage.getItem("queueDevice") == undefined
        //             ? new QueueDevice()
        //             : { ...JSON.parse(sessionStorage.getItem("queueDevice")) };
        //     device.dokters = [];
        //     device.friendlyName = "APPOINTMENT";
        //     device.notificationId =
        //         this.userNotification != null
        //             ? this.userNotification.notificationId
        //             : null;
        //     device.uniqueId = sessionId;
        //     this.setSessionId(sessionId);
        //     this.saveQueueDevice(device);
        // },
        connect() {
            const  checkDokter = (id) => {
                if(this.dokterArray && this.dokterArray.findIndex(item => item.id == id) >= 0) return true
                return false
            }
            let base = process.env.VUE_APP_WEB_SOCKET;
            let url = `${base}/?access_token=${localStorage.tokens}`;
            var socket = new SockJS(url);

            this.SET_STOMP_CLIENT(Stomp.over(socket));
            // to turn th console log off
            this.stompClient.debug = () => {};
            var e = jQuery.Event("click");
            this.stompClient.connect(
                {},
                frame => {
                    this.stompClient.subscribe("/topic/webbo", tick => {
                      let msg = JSON.parse(tick.body);
                        let checkTick = false
                        var event = new CustomEvent("basket");
                        if(msg.entityType == 'appointment') {
                            this.msgTick = {...msg.entityData }
                        }
                        if (msg.entityType == 'appointment'
                        ) {
                            if(this.msgTick.dokterId) {
                                checkTick = checkDokter( this.msgTick.dokterId)
                            } else {
                                checkTick = true
                            }
                        }
                        if(checkTick) {
                            if(this.timeOutGlobal) clearTimeout(this.timeOutGlobal)
                            this.timeOutGlobal = setTimeout(() => {
                                document.dispatchEvent(event);
                            }, 500)
                        }
                    });
                },
                error => {
                    this.disconnect();
                    // console.log("STOMP Closed", error);
                    setTimeout(this.connect(), 2000);
                }
            );
        },
        basketball() {
            function addClickHandler(handler) {
                document.addEventListener("basket", handler);
            }
            const clicks = fromEventPattern(addClickHandler);
            // debounce with rxjs
            const result = clicks.pipe(debounceTime(500));
            result.subscribe(x => {
                this.reloadCalendar();
                var isActive = window.document.hasFocus();
                let dokter = ''
                if(this.dokterArray && this.dokterArray.length > 0) {
                   dokter = this.dokterArray.find(item => this.msgTick && this.msgTick.dokterId == item)
                }
                if (!isActive)  {
                    this.showNotif(dokter ? dokter.nama : '');
                } else {
                    toastr.info('Ada perubahan Reservasi', '', {...this.toastPreventDefault, preventDuplicates: false})
                }
                this.msgTick = {}
            });
        },
        // countDownTimer() {
        //     if (this.countDown > 0) {
        //         setTimeout(() => {
        //             this.countDown -= 1;
        //             this.countDownTimer();
        //         }, 1000);
        //     } else {
        //         this.reloadCalendar();
        //         this.countDown = 10;
        //         this.countDownTimer();
        //     }
        // },
        disconnect() {
            if (this.stompClient && this.stompClient.status === "CONNECTED") {
                this.stompClient.disconnect();
            }
        },
        showNotif() {
            // Let's check if the browser supports notifications
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
            }

            // Let's check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
                // If it's okay let's create a notification
                var notification = new Notification("Doctor Appointment");
            }

            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function(permission) {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        var notification = new Notification(
                            "Doctor Appointment"
                        );
                    }
                });
            }

            if (notification !== undefined && notification !== null) {
                notification.onclick = function(event) {
                    window.focus();
                };
            } else {
                toastr.error("please allow notification");
            }
        }
    },
    mounted() {
        this.setShowModalHistory(false);
        let dokterLogin = checkPermission.isCanAccess(
            "DokterResource.GET.Dokter.Current"
        );
        this.setDoctorLogin(dokterLogin);
        // console.log('doctor login', this.doctorLogin)
        if (!this.doctorLogin) {
            this.getSpecialtiesDropdownData();
            this.getDokterDropdownData()
        } else {
            this.getCurrentDoctor();
        }
    },
    created() {
        // this.onInit();
        this.connect();
        this.basketball();
        // this.countDownTimer()
    },
    destroyed() {
        this.disconnect();
    }
};
</script>

<style lang="scss" scoped></style>
