<template>
    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1314_2020)">
            <path
                d="M2.6665 11.4284L3.04746 7.99981L10.1281 1.2732C10.4277 0.988619 10.8995 0.994667 11.1916 1.28683L13.1754 3.27059C13.4729 3.56813 13.4729 4.05054 13.1754 4.34809L6.09508 11.4284H2.6665Z"
                :stroke="strokeColor" 
                stroke-width="1.52381" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
            <path
                d="M2.6665 11.4287L3.04746 8.00014L7.46193 3.92524C7.76242 3.64786 8.2283 3.65717 8.51747 3.94634L10.5087 5.93758C10.8063 6.23512 10.8063 6.71754 10.5087 7.01508L6.09508 11.4287H2.6665Z"
                :strokeColor="strokeColor" 
                stroke-width="1.52381" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
            <path 
                d="M1.9043 14.8574H14.0948" 
                :stroke="strokeColor" 
                stroke-width="1.52381" 
                stroke-linecap="round"
                stroke-linejoin="round" 
            />
        </g>
        <defs>
            <clipPath id="clip0_1314_2020">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#5968DD"
        },
        width : {
            type : Number,
            default: 16
        },
        height : {
            type: Number,
            default: 16
        },
    }
};
</script>

<style></style>
