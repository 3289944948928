<template>
    <div class="multiselect-container" @click="toggleDropdown">
        <Multiselect
            ref="multiselect"
            v-model="internalValue"
            :searchable="searchable"
            :options="listOptions"
            :multiple="multiple"
            :loading="isLoading"
            :internal-search="internalSearch"
            :clear-on-select="clearOnSelect"
            :close-on-select="closeOnSelect"
            :show-no-results="showNoResult"
            :hide-selected="hideSelected"
            :show-labels="showLabels"
            :max-height="maxHeight"
            :limit="limit"
            :taggable="taggable"
            :allow-empty="allowEmpty"
            :selected-label="selectedLabel"
            :select-label="selectLabel"
            :deselect-label="deselectLabel"
            :group-values="groupValues"
            :group-label="groupLabel"
            :group-select="enableGrouping"
            @tag="addTag"
            @select="onSelect"
            @remove="onRemove"
            @search-change="asyncFind"
            :label="label"
            :track-by="trackBy"
            :placeholder="placeholder"
            :class="{ 'custom-multiselect': true, 'no-toggle': !hasToggle }"
        >
            <!-- <template v-if="useSelection" #option="{ option }">
                <div class="option-content">
                    <div class="doctor-name">{{ option.nama }}</div>
                    <div v-if="!enableGrouping && option.dokterSpesialisasis && option.dokterSpesialisasis.length" class="specialist-tag">
                        ( {{ option.dokterSpesialisasis[0].spesialisasi.namaIndo }} )
                    </div>
                </div>
            </template> -->

            <template v-if="useSelection" #selection="{ values, remove }">
                <div class="multiselect-selection-container">
                    <span
                     v-for="(option, index) in values"
                     :key="index"
                     class="multiselect-tag"
                    >
                    {{ option.nama }}
                    <span class="multiselect-tag-icon"> 
                        <slot name="icon" :option="option" />
                    </span>
                        <span class="multiselect-tag-close" @click.stop="remove(option)">
                            <XIcon />
                        </span>
                    </span>
                </div>
            </template>

            <template v-else #selection="{ values }">
                <span 
                 v-if="values.length" 
                 class="multiselect-single"
                 >
                {{ values.length }} {{ text }}
                </span>
            </template>
            <!-- <template #tag="{ option, remove }">
                <span class="multiselect-tag">
                    {{ option.name }}
                        <span class="multiselect-tag-close" @click="remove(option)">
                            <XIcons :width="16" :height="16" />
                        </span>
                </span>
            </template>   -->
            
            <template #caret v-if="showCaret">  
                <div v-if="!isLoading" class="icon" @click.stop="toggleDropdown">
                    <ChevronIcon 
                        :position="isDropdownActive ? 'up' : 'down'"
                        :width="7"
                        :height="14"
                        :strokeColor="'#23415B'"
                    />
                </div>
            </template>
    </Multiselect>
    </div>
</template>

<script>
import ChevronIcon from '../_icons/ChevronIcons.vue'
import XIcon from '../_icons/XIcon.vue';
import  Multiselect  from 'vue-multiselect';

export default {
    components: {
        Multiselect,
        ChevronIcon,
        XIcon
    },
    data() {
        return {
            internalValue: this.modelValue,
            isDropdownActive: false,
        }
    },
    props: {
        searchable: {
            type: Boolean,
            default: true
        },
        allowEmpty: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default:''
        },
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [Array, Object]
            
        },
        listOptions: {
            type: Array,
            required: true,
            default: () => []
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        useSelection: {
            type: Boolean,
            default: true
        },
        showCaret: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        },
        internalSearch: {
            type: Boolean,
            default: false
        },
        closeOnSelect: {
            type: Boolean,
            default: false
        },
        clearOnSelect:{
            type: Boolean,
            default: true
        },
        showNoResult: {
            type: Boolean,
            default: false
        },
        hideSelected: {
            type: Boolean,
            default: false
        },
        selectLabel: {
            type: String,
            default: 'Tekan enter untuk memilih', 
        },
        deselectLabel: {
            type: String,
            default: 'Tekan enter untuk menghapus'
        },
        showLabels: {
            type: Boolean,
            default: false
        },
        selectedLabel: {
            type: String,
            default: 'Terpilih'
        },
        maxHeight: {
            type: Number,
            default: 165
        },
        trackBy: {
            type: String,
            default: 'name'
        },
        placeholder: {
            type: String,
            default: ''
        },
        limit: {
            type: Number,
            default: 6
        },
        // limitText: {
        //     type: Function,
        //     default: (count)=> `and ${count} other clusters`
        // },
        taggable: {
            type: Boolean,
            default: false
        },
        groupValues: {
            type: String,
            default: ''
        },
        groupLabel: {
            type: String,
            default: ''
        },
        enableGrouping: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasToggle() {
            return this.showCaret
            }
        },
        beforeUnmount() {
            document.removeEventListener('click', this.handleOutsideClick, true);
        },
    
    methods: {
        onSelect(option) {
            this.$emit('select', option)
        },
        onRemove(option) {
            this.$emit('remove', option)
        },
        asyncFind(option) {
            this.$emit('search-change', option)
        },
        toggleDropdown(event) {
            event.stopPropagation()
            if(this.isDropdownActive) {
                this.closeDropdown();
            } else {
                this.isDropdownActive = true;
                this.$refs.multiselect.activate();
                document.addEventListener('click', this.handleOutsideClick, true);
            }
        },
        closeDropdown() {
            this.isDropdownActive = false;
            this.$refs.multiselect.deactivate();
            document.removeEventListener('click', this.handleOutsideClick, true);
        },
        handleOutsideClick(event) {
            if (!this.$refs.multiselect.$el.contains(event.target)) {
                this.closeDropdown();
            }
        },
        addTag(newTag) {
            const tag = { name: newTag };
            this.$emit('add-tag', tag)
        }
         
    },
    watch: {
        internalValue(newValue) {
            this.$emit('update:modelValue', newValue);
        },
        modelValue(newValue) {
            this.internalValue = newValue;
        }
    }
    // computed: {
    //     formattedOptions() {
    //         if (this.enableGrouping) {
    //             return this.listOptions;
    //         } else {
    //             return this.listOptions.reduce((acc, group) => {
    //                 return acc.concat(group[this.groupValues]);
    //             }, []);
    //         }
    //     }
    // }

}

</script>

<style scoped>

.specialist-tag {
    font-size: 0.8em;
}

.multiselect-single{
    display: inline-flex;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
    color: rgba(35, 65, 91, 1);
    margin-bottom: 0;
}

.multiselect-selection-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.multiselect-tag {
    display: inline-flex;
    align-items: center;
    background-color: #5578EB;
    color: #FFFFFF;
    border: none;
    border-radius: 12px;
    margin-right: 5px;
    font-size: 12px;
    padding: 4px 4px 4px 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
}

.multiselect-tag-icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.multiselect-tag-close {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

.multiselect-placeholder {
    font-size: 14px;
    color: #A0A0A0;
    cursor: pointer;
    margin-bottom: 0px !important;
} 

.multiselect-container {
    position: relative;
}

.multiselect-label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    display: block;
    margin-bottom: 10px; 
}

.custom-multiselect >>> .multiselect__content-wrapper {
    border-radius: 6px;
    max-height: 164px; 
    overflow-y: auto; 
    position: absolute;
    top: 52px; 
    z-index: 3; 
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2); 
}

.custom-multiselect >>> .multiselect__option {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
}

.custom-multiselect >>> .multiselect__option--highlight {
    background-color: #add8e6; 
}

.icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
}



.multiselect__content-wrapper {
    max-height: 200px;
    overflow-y: auto;
}

/* For Webkit browsers like Chrome, Safari */
.custom-multiselect >>> .multiselect__content-wrapper::-webkit-scrollbar {
    width: 4px; 
}

.custom-multiselect >>> .multiselect__content-wrapper::-webkit-scrollbar-track {
    background: transparent; 
}

.custom-multiselect >>> .multiselect__content-wrapper::-webkit-scrollbar-thumb {
    background: rgba(193, 193, 193, 1);
    border-radius: 2px; 
    box-shadow: inset 0px 3px 20px 0px rgba(35, 65, 91, 0.1);
    border: 0; 
}

.custom-multiselect >>> .multiselect__content-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(163, 163, 163, 1);
}

/* For Firefox */
.custom-multiselect >>> .multiselect__content-wrapper {
    scrollbar-width: thin; 
    scrollbar-color: rgba(193, 193, 193, 1) transparent; 
    
}

.custom-multiselect.no-toggle >>> .multiselect__select {
    display: none;
}

.custom-multiselect >>> .multiselect__tags {
    padding: 8px 40px 8px 8px !important;
}
</style>
