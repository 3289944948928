<template>
  <div>
    <div class="modal-bodi">
        <div v-if="caseData.MODE === 'SEARCH_PASIEN'">
          <PatientSearch />
        </div>
        <div v-else-if="caseData.MODE === 'DETAIL_PASIEN'">
          <DetailPatient />
        </div>
        <div v-else-if="caseData.MODE === 'FORM_PASIEN'">
          <FormPatient />
        </div>
    </div>
    <Footer 
      v-if="caseData.MODE === 'DETAIL_PASIEN' || caseData.MODE === 'FORM_PASIEN'"
      :show-previous="false"
      :show-cancel="caseData.MODE === 'FORM_PASIEN'"
      :show-next="true"
      :is-form-complete="isFormComplete"
      @nextContent="nextStepContent"
    ></Footer>
  </div>
</template>

<script>
import PatientSearch from './PatientSearch.vue'
import DetailPatient from './DetailPatients.vue'
import FormPatient from './FormPatient.vue'
import Footer from './Footer.vue'

export default {

  inject:['caseData', 'setCurrentStep', 'sendInfoContent'],

  components: {
    PatientSearch,
    DetailPatient,
    FormPatient,
    Footer
  },
  computed: {
    isFormComplete() {
            return this.caseData.MODE == 'DETAIL_PASIEN' || (this.caseData.MODE == 'FORM_PASIEN' && this.caseData.BUTTON_FORM == 'ENABLE')
            // if (this.currentContent !== 'STEP_1' || this.caseData.MODE !== 'FORM_PASIEN') return true;
            // return (
            //     this.caseData.FORM &&
            //     this.caseData.FORM.NAMA_STATUS === 'SUCCESS' &&
            //     this.caseData.FORM.JENIS_KELAMIN_STATUS === 'SUCCESS' &&
            //     this.caseData.FORM.TANGGAL_LAHIR_STATUS === 'SUCCESS' &&
            //     this.caseData.FORM.NO_TELP_STATUS === 'SUCCESS'
            // )
            // return this.caseData.BUTTON_FORM === 'ENABLE'
        }
  },
  methods: {
    nextStepContent() {
      this.setCurrentStep('STEP_2')
      const pasien = this.caseData.MODE == 'DETAIL_PASIEN' ? {
        SMS: this.caseData.SELECTED_PASIEN.noTelp,
        EMAIL: this.caseData.SELECTED_PASIEN.email
      } : this.caseData.MODE == 'FORM_PASIEN' ? {
        SMS: this.caseData.FORM.NO_TELP,
        EMAIL: this.caseData.FORM.EMAIL
      } 
      :{}
      this.sendInfoContent(pasien)
    }
  }
}
</script>

<style scoped>
.modal-bodi {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-bottom: 0;
}
</style>