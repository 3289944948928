import { createMachine } from "xstate";
export const machineStep3 = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGUAqBRACgWgMzYCV1l0CA1AQWQEkA6agOWtQGIBtABgF1FQAHAPawAlgBdhAgHa8QAD0QBGACwAOWgE5Nm3AFYATLj16FuADQgAnolw3aenR0cKAbHqXqjAX0-m0WPITEpJQ0tMgAEgDyAOoA+pgUAOLotJjoDABSFACyjCywYAA2YADGogh8YJIAVgCGALbCkpw8SCCCIuJSMvIIRnq0Orq4SiNKehw2zuZWCMocGgDsKhzqSs7O6vpDOt6+GDj4RCTkVHQRMfFJKWmZOYz0TKwtMh1iEtJtvQ7OtAoKixcuC2uFc4yUM0QehUAwcjmci0c6hUALWexAfkOgROIXOUTiCWSqXSWVyDFoyVQsQAIhRUBR4iT7gwWBApGBaE0AG4CADWHMxAWOwTOYXxVyJt1JD0pNLpDKlzIQ3IEJVqXWa3BebTeGp6iHUHCUGn+iz0HgROn+6khc2cC0N6kWOkW6hczh0ToU6MFRyCp1CFwJ11o2WIyGuLCafAAruV6nBYLUYNr+EJ3t0vlDFrg-koOAY3DZFs69LadAiNIa9IjNMtNnofQchf7cWLLoSbkyyRT0FTafTGXcySwwAAnMcCMe0PiFdUAMyn9TCzb9ONFQYlXeHMr7csHirJyskPLVGpaqfa6b1WYQgN+myGHA9KiUSh0SgUttBi1ojicbqTBwJgmN4PggJIAgQHAMi+tiIo0K814fPqCDYNMliINgCjGIMxgeMBKjOAoH45k2-hrghdCMMwSGdCht7jN+hh2O4yI4bgMKGLs4FwcKAZ4h21x0RmnygL0OG-DsIxjBMUy2ngxoFgobr6CMejEbgizkVi-FtpunbEjuDAiTe4mKIsxrSaMb5yaC5a5koywcCoXqLM4uAqeoOktuugbioZh4PDRqCmQx5kILov7zK4OhxYYhoera0IKH+-7vqCkz2u+PmUQJ7bBpK3a7v28pDtKJk6shmYRR6vyjJZ2gliinnJS6gz-p6oIfgiuC5fB+UGSGQXkpuAAy1BoOVzJhTVcgGpZtAIgW0L6J+Hg2phkWOLQKgrEMlnOOMnr9XpG4BcNxWjegY3oAAwlSI2zWJ80IO4Cwwq+0JHW+wFlltAILMCVqWasxbuTx+wUQN+kXUSYbIBGyTPahRg6HmBaGKMWmlv9szjKlmgFioOMcDmTmna251CfD4YhiFKO3uav6GjoKieu+RrOEROjlqstDvdCKhmhp0Jk5TfmCYVKQI0jKSMJgACqVKy8JVX0XN3xHYMcK6I4r49eWmgmm4R24GTxGaBLVEFVuRkVbQpAEJEBCxBNU1PeromoR4AwevoboKKsbNEbab6pW+nk-BbhraWBQA */
    id: "STEP-3-RESERVASI",
    initial: "INIT",
    states: {
      INIT: {
        always: {
          target: "SHOW_PAGE",
          actions: [],
          meta: {},
        },
      },
      SHOW_PAGE: {
        states: {
          PENJAMIN: {
            initial: "INIT",
            states: {
              INIT: {
                always: {
                  target: "GET_DATA_PENJAMIN",
                  actions: [],
                  meta: {}
                },
              },

              GET_DATA_PENJAMIN: {
                invoke: {
                  input: {},
                  src: "getDataPenjamin",

                  onDone: [
                    {
                      target: "SHOW_LIST_PENJAMIN",
                      actions: ["setCtxListPenjamin"],
                      meta: {},
                    },
                  ],

                  onError: "ERROR_LIST_PENJAMIN"
                },
              },

              SHOW_LIST_PENJAMIN: {},
              SELECT_PENJAMIN: {},
              ERROR_LIST_PENJAMIN: {}
            },
            on: {
              "select:penjamin": {
                target: ".SELECT_PENJAMIN",
                actions: ["setCtxSelectPenjamin"],
                meta: {},
                internal: false
              },
            },
          },
          MESSAGE: {
            initial: "INIT",
            states: {
              INIT: {},
              INPUT_MESSAGE: {},
            },
            on: {
              "input:message": [
                {
                  target: ".INPUT_MESSAGE",
                  actions: ["setCtxInputMessage"],
                  meta: {},
                  internal: false,
                },
              ],
            },
          },
        },
        type: "parallel",
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  }
);