<template>
    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1086_8)">
            <path 
                d="M4.66634 1.33317L11.333 7.99981L4.66634 14.6665" 
                :stroke="strokeColor" 
                stroke-width="2"
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
        </g>
        <defs>
            <clipPath id="clip0_1086_8">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#929292"
        },
        width : {
            type : Number,
            default: 16
        },
        height : {
            type: Number,
            default: 16
        },
    }
};
</script>

<style></style>
