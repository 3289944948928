<template>
    
  <!-- <div
    :id="modalName"
    class="modal fade"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >  -->
  <div class="modal show" tabindex="-1" style="display: block">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="border-radius: 12px;">
      <div class="modal-header">
        <button @click="closeDetail" type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
        <p v-if="formData.Form ==='INPUT'"  class="modal-title mt-4 mb-3" style="color: #23415B; font-size: 20px; font-weight: 600; line-height: 24.38px;">No. Rekam Medik <span style="color: #23415B; font-size: 20px; font-weight: 700;">{{ formData.PatientFetch.Mrn }}</span> sudah digunakan</p>
        <p v-else class="modal-title mt-4 mb-3" style="color: #23415B; font-size: 20px; font-weight: 600; line-height: 24.38px;">No. Rekam Medik <span style="color: #23415B; font-size: 20px; font-weight: 700;">{{ formData.PatientFetch.Mrn }}</span> sudah digunakan pada pasien berikut</p>
        <div class="patient-header">
          <div class="row">
            <div class="col text-left" style="color: #23415B; font-size: 12px;">
              <p>
                Nama
              </p>
            </div>
            <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px; margin-bottom: 0px;">
              <p>
                {{ formData.PatientFetch.Nama }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-left" style="color: #23415B; font-size: 12px;">
              <p>
                Tanggal lahir
              </p>
            </div>
            <div class="col text-left font-weight-bold" style="color: #23415B; font-size: 12px;">
              <p>
                {{ formatDate(formData.PatientFetch.TanggalLahir) }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-left" style="color: #23415B; font-size: 12px;">
              <p>
                Gender
              </p>
            </div>
            <div class="col text-left font-weight-bold" style="color: #23415B; font-size: 12px;">
              <p>
                {{ $t(formData.PatientFetch.Gender) }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-left" style="color: #23415B; font-size: 12px;">
              <p>
                No. Telepon
              </p>
            </div>
            <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
              <p>
                {{ formData.PatientFetch.NoTelp }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-left" style="color: #23415B; font-size: 12px;">
              <p>
                Email
              </p>
            </div>
            <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
              <p>
                {{ formData.PatientFetch.Email }}
              </p>
            </div>
          </div>
        </div>

        <p v-if="formData.Form ==='EDIT'" class="mt-3" style="color: #23415B; font-weight: 400; font-size: 14px; line-height: 17.07px; margin-bottom: 0px;">Data No. Rekam Medik pasien di atas akan dihapus jika Anda melanjutkan dengan data nomor yang sama pada pasien berbeda dari No. Rekam Medik yang Anda pilih</p>
        <p v-else class="mt-3" style="color: #23415B; font-weight: 400; font-size: 14px; line-height: 17.07px; margin-bottom: 0;">Data pasien di atas akan digantikan dengan data baru yang Anda pilih</p>
        <div style="padding-bottom: 40px; padding-top: 24px;">
          <button @click="closeDetail" type="button" class="btn font-weight-semibold" 
            style="background: #19558C; color: #fafafa; border-radius: 10px; width: 92px; font-size: 14px;">Tutup</button>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>

//import CloseIcon from '../../_icons/CloseIcons.vue'

export default {
components: {
  //CloseIcon
},
props: {
  modalName: {
    type: String,
    default: 'InfoPatientModal'
  }
},
inject: ['formData', 'closeDetail'],
// inject: ['formData'],
methods: {
  formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('id-ID', {day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '-');
  },
  // closeDetail() {
  //    // this.closeDetail
  //   // function for button tutup
  // }
},
}
</script>

<style scoped>

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 0;
  }

  .patient-header {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #C1C1C1;
    background: #FAFAFA;
    width: 319px;
    padding-bottom: 0px;
  }
</style>
