<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="add-icon"
    >
        <path
            d="M12 20V12M12 12V4M12 12H20M12 12H4"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#5578EB"
        },
        width : {
            type : Number,
            default: 24
        },
        height : {
            type: Number,
            default: 24
        },
    }
};
</script>

<style scoped>
.add-icon {
    color: inherit;
}

</style>
