<template>
    <div>
        <div class="form-inner">
            <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 2.5rem;">
                <span class="mb-0 font-weight-bold"
                    style="font-size: 20px; line-height: 30px; color: #636363;">Tambahan</span>
            </div>
            <div class="reservation-form mt-4">
                <div class="row">
                    <div class="col-md-6" style="padding-left: 4rem;">
                        <div class="form-group">
                            <label for="doctorSelect" style="font-size: 16px; font-weight: 600; color: #636363;">Jenis
                                pembayaran<span style="color: #C2283C;">*</span></label>
                            <!-- <select 
                                class="form-control" 
                                id="doctorSelect"
                                v-model="selectedPenjamin"
                                style="background-color: #F8FAFF; border: 1px solid #E4E4E4; font-size: 14px; color: #636363; font-weight: 600;"
                            >
                                <option value="" disabled selected>Pilih jenis pembayaran</option>
                                <option 
                                    v-for="penjamin in caseData.LIST_PENJAMIN" 
                                    :key="penjamin.id" 
                                    :value="penjamin"
                                >
                                    {{ penjamin.nama }}
                                </option>
                            </select> -->
                            <MultiSelect 
                                :modelValue="selectedPenjamin"
                                :list-options="caseData.LIST_PENJAMIN"
                                :label="'nama'"
                                :trackBy="'nama'"
                                :show-caret="true"
                                :multiple="false"
                                :close-on-select="true"
                                :allowEmpty="false"
                                :is-loading="caseData.STATUS_LIST_PENJAMIN == 'LOADING'"
                                @select="onSelect"
                            />
                        </div>
                        <div class="form-group">
                            <label for="pesanTextarea"
                                style="font-size: 16px; font-weight: 600; color: #636363;">Pesan</label>
                            <textarea 
                                class="form-control" 
                                id="pesanTextarea" 
                                rows="4"
                                v-model="message"
                                style="border: 1px solid #E4E4E4; font-size: 14px; color: #636363;"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer 
            :show-previous="true"
            :show-cancel="true"
            :show-next="true"
            :is-form-complete="isFormComplete"
            @backContent="backStepContent"
            @nextContent="saveReservasi"
        />
    </div>
</template>

<script>
import Footer from './Footer.vue';
import MultiSelect from '../../../_select/MultiSelect.vue'

export default {
    inject: ['caseData', 'selectPenjamin', 'inputMessage', 'setCurrentStep', 'saveReservasi'],
    components: {
        MultiSelect,
        Footer
    },
    computed: {
        selectedPenjamin: {
            get () {
                return this.caseData.SELECTED_PENJAMIN
            },
            set(val) {
                this.selectPenjamin(val)
            }
        },
        message: {
            get() {
                return this.caseData.MESSAGE
            },
            set(val) {
                this.inputMessage(val)
            }
        },
        isFormComplete() {
            return this.caseData.STATUS_PENJAMIN;
        }
    },
    methods: {
        onSelect(selectedOptions) {
            this.selectedPenjamin = selectedOptions
        },
        backStepContent() {
            this.setCurrentStep('STEP_2')
        }
    }
}
</script>

<style scoped>
.form-inner {
    padding: 1rem 2rem 1rem;
    min-height: 535px;
}
</style>