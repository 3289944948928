<template>
<div style="min-height: 469px;">
    <div class="d-flex justify-content-between align-items-center mt-3" style="margin-bottom: 2.5rem;">
        <span class="mb-0 font-weight-bold" style="font-size: 20px; line-height: 30px; color: #636363;">Isi Data
            Pasien</span>
        <button class="btn btn-outline-primary"
            style="font-size: 14px; font-weight: 700; border-radius: 12px; height: 40px;"
            @click="goToSearchPatient"
            >
            Cari pasien
            <MagnifierIcon :strokeColor="'#5578EB'"/>
        </button>
    </div>
    <div style="padding-left: 3rem; padding-right: 3rem;">
        <div class="row">
            <div class="col-md-6 pr-4" style="padding-bottom: 2.5rem;">
                <label for="namaPatient"
                    style="font-size: 16px; font-weight: 600; color: #636363; line-height: 19.5px;">Nama Pasien<span
                        style="color: #C2283C;">*</span></label>
                <input v-model="formNama" type="text" class="form-control" id="namaPatient" placeholder="Isi nama pasien" :class="caseData.FORM.NAMA_STATUS === 'ERROR' ? 'is-invalid': ''">
                <p v-if="caseData.FORM.NAMA_STATUS === 'ERROR'" class="font-error">Nama harus diisi</p>
            </div>
            <div class="col-md-6 pl-4">
                <label for="email"
                    style="font-size: 16px; font-weight: 600; color: #636363; line-height: 19.5px;">Email</label>
                <input v-model="formEmail" type="email" class="form-control" id="email" placeholder="Isi email">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 pr-4" style="padding-bottom: 2.5rem;">
                <label 
                    for="tanggalLahir"
                    style="font-size: 16px; font-weight: 600; color: #636363; line-height: 19.5px;"
                >
                    Tanggal Lahir<span style="color: #C2283C;">*</span>
                </label>
                    <div class="position-relative">
                        <vue-date-picker
                            v-model="formTanggalLahir"
                            :placeholder="'Tanggal lahir'"
                            :input-class="`form-control ${caseData.FORM.TANGGAL_LAHIR_STATUS === 'ERROR' ? 'is-invalid' : ''}`"
                            :maxDate="new Date()"
                            :showDefaultIcon="false"
                            :clearable="false"
                            :disabled-date="disabledDate"
                        >
                        </vue-date-picker>
                        <DateIcon class="icon" :width="16" :height="16" :strokeColor="'#5578EB'"/>
                    </div>
                    <p v-if="caseData.FORM.TANGGAL_LAHIR_STATUS === 'ERROR'" class="font-error">Tanggal lahir harus diisi</p>
            </div>
            <div class="col-md-6 pl-4" style="padding-bottom: 2.5rem;">
                <label for="noTelepon"
                    style="font-size: 16px; font-weight: 600; color: #636363; line-height: 19.5px;">No. Telepon<span
                        style="color: #C2283C;">*</span></label>
                
                    <PhoneNumberPatient
                        v-model="formNoTelp"
                        :is-country-component="caseData.IS_COMPONENT_COUNTRY"
                        :errorClass="caseData.FORM.NO_TELP_STATUS === 'ERROR' ? 'ERROR' : ''"
                        @sweetAlertAction="handleSweetAlert"
                    >
                    </PhoneNumberPatient>
                    <p v-if="caseData.FORM.NO_TELP_STATUS === 'ERROR'" class="font-error">Format nomor telepon salah</p>
            </div>
        </div>
        <!-- Radio Button -->
        <div class="row mb-3">
            <div class="col-md-6">
                <label style="font-size: 16px; font-weight: 600; color: #636363; line-height: 19.5px;">Jenis Kelamin
                    <span style="color: #C2283C;">*</span></label>
                <div class="mt-1">
                    <div v-for="gender in caseData.LIST_JENIS_KELAMIN" :key="gender" class="form-check form-check-inline" :class="{ 'ml-3': gender.id !== 1 }">
                        <input 
                            class="form-check-input" 
                            type="radio" 
                            :id="gender" 
                            :value="gender"
                            :checked="caseData.FORM.JENIS_KELAMIN.toLowerCase() === gender.toLowerCase()"
                            @change="selectedGender"
                            name="jenisKelamin"
                        >
                        <label class="form-check-label" :for="gender"
                            style="font-size: 16px; font-weight: 500; color: #636363; line-height: 19.5px;">
                            {{ $t(gender) }}
                        </label>
                    </div>
                    <p v-if="caseData.FORM.JENIS_KELAMIN_STATUS === 'ERROR'" class="font-error">Jenis kelamin harus diisi</p>
                </div>
            </div>
            <div class="col-md-6 pl-4">
                <label for="noMedicalRecord"
                    style="font-size: 16px; font-weight: 600; color: #636363; line-height: 19.5px;">No. Medical
                    Record</label>
                <input v-model="formMedicalNo" type="text" class="form-control" id="noMedicalRecord" placeholder="Isi nomor medical record">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MagnifierIcon from '../../../_icons/MagnifierIcon.vue';
import PhoneNumberPatient from '../../../_general/InputPhoneNumber.vue'
import DateIcon from '../../../_icons/DateIcon.vue'
import VueDatePicker from './vueDatePick.vue'
import SweetAlertTemplate from '../../../_general/SweetAlert.vue'

const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    inject: ['caseData', 'inputFormText', 'goToSearchPatient', 'showOpenSweetAlert', 'hideSweetAlert'],
    components: {
        MagnifierIcon,
        PhoneNumberPatient,
        DateIcon,
        VueDatePicker
    },
    watch: {
        isShowAlert(val) {
            if(val) {
                this.dialogPhone()
            }
        }  
    },
    computed: {
        isShowAlert() {
            return this.caseData.DIALOG_PHONE
        },
        formNama: {
            get() {
                return this.caseData.FORM.NAMA
            },
            set(val) {
                this.inputFormText({key: 'NAMA', value: val})
            }
        },
        formTanggalLahir: {
            get() {
                return this.caseData.FORM.TANGGAL_LAHIR
            },
            set(val) {
                this.inputFormText({key: 'TANGGAL_LAHIR', value: val})
            }
        },
        formNoTelp: {
            get() {
                return this.caseData.FORM.NO_TELP
            },
            set(val) {
                this.inputFormText({key: 'NO_TELP', value: val})
            }
        },
        formMedicalNo: {
            get() {
                return this.caseData.FORM.NOMOR_MRN
            },
            set(val) {
                this.inputFormText({key: 'NOMOR_MRN', value: val})
            }
        },
        formEmail: {
            get() {
                return this.caseData.FORM.EMAIL
            },
            set(val) {
                this.inputFormText({key: 'EMAIL', value: val})
            }
        },
    },
    methods: {
        selectedGender(_evt) {
            this.inputFormText({key: 'JENIS_KELAMIN', value: _evt.target.value})
        },
        dialogPhone() {
            if (this.caseData.DIALOG_PHONE) {
                this.showDialogPhone()
            }
        },
        handleSweetAlert() {
            this.showOpenSweetAlert()
            // this.showSweetAlert = value
        },
        showDialogPhone() {
            new SweetAlert().showInfo({
                swalTitle: "Info",
                swalText: "Notifikasi (SMS) tidak akan terkirim ke nomor selain nomor Indonesia (selain +62)",
                swalType: "info",
                onConfirmButton: () => this.hideSweetAlert()
            })
        },
        disabledDate(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date > today;
        },
    }
}

</script>

<style scoped>
.font-error {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    color: #C2283C;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 8px
}

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #999;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}
</style>